<template>
  <b-container>
    <b-form-group
      :label="itemName"
      class="mt-2"
    >
      <b-row v-for="(item, index) in items" :key="index" cols="12">
        <b-form inline class="mt-0 mb-1 w-100">
          <b-input-group
            :prepend="item[labelField]" class="mb-2 mr-sm-2 mb-sm-0 w-75" size="sm"
          >
            <b-input
              id="new-item-input"
              v-model="item[valueField]"
              type="text"
              size="sm"
              :placeholder="`Enter new ${itemName}`"
            />
          </b-input-group>
          <b-button v-if="checkDeleteFirst(index)"
                    variant="outline-danger" class="ml-1"
                    size="sm"
                    @click="remove(index)"
          ><feather-icon icon="TrashIcon" />
          </b-button>
        </b-form>
      </b-row>
      <b-button
        variant="outline-primary"
        size="sm"
        @click="add"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Add {{ itemName }}</span>
      </b-button>
    </b-form-group>
  </b-container>
</template>

<script>
export default {
  name: 'Repeater',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    defaultItem: {
      type: Object,
      default: () => ({ label: '', value: '' }),
    },
    itemName: {
      type: String,
      default: () => '',
    },
    idField: {
      type: String,
      default: () => 'id',
    },
    labelField: {
      type: String,
      default: () => 'label',
    },
    valueField: {
      type: String,
      default: () => 'value',
    },
    useIndexAsId: {
      type: Boolean,
      default: () => true,
    },
    allowDeleteFirst: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    add() {
      if (this.useIndexAsId) {
        const newObject = { ...this.defaultItem }
        newObject[this.idField || 'id'] = this.items.length + 1
        this.items.push(newObject)
      } else {
        this.items.push({ ...this.defaultItem })
      }
    },
    remove(index) {
      this.items.splice(index, 1)
    },
    checkDeleteFirst(index) {
      return this.allowDeleteFirst || index > 0
    },
  },
}
</script>
